/* eslint-disable no-nested-ternary */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import Pulse from 'react-reveal/Pulse';
import { Link } from 'react-scroll';
import { SiSlides } from 'react-icons/si';
import { FaLinkedin, FaGithub, FaYoutube, FaAngleUp } from 'react-icons/fa';
import PortfolioContext from '../../context/context';
import GithubButtons from '../GithubButtons/GithubButtons';

import Logo from '../../images/logo.png';

import { githubButtons } from '../../mock/data';

const Footer = (props) => {
  const [logoHovered, setlogoHovered] = useState(false);

  const { footer } = useContext(PortfolioContext);
  const { networks } = footer;
  const { isEnabled } = githubButtons;
  const { hero } = props;

  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <span className="back-to-top">
          <Link to={hero} smooth duration={1000}>
            <FaAngleUp className="back-to-top-arrow" />
          </Link>
        </span>
        <Pulse spy={logoHovered}>
          <div className="footer-logo-placeholder">
            <Link to={hero} smooth duration={1000}>
              <img
                onMouseEnter={() => setlogoHovered(!logoHovered)}
                className="footer-logo"
                src={Logo}
                alt="Logo"
              />
            </Link>
          </div>
        </Pulse>
        <div className="social-links">
          {networks &&
            networks.map((network) => {
              const { id, name, url } = network;
              return (
                <a
                  key={id}
                  href={url}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label={name}
                  className={`social-links-${name}`}
                >
                  {name === 'LinkedIn' ? (
                    <FaLinkedin />
                  ) : name === 'GitHub' ? (
                    <FaGithub />
                  ) : name === 'YouTube' ? (
                    <FaYoutube />
                  ) : (
                    <SiSlides />
                  )}
                </a>
              );
            })}
        </div>
        <hr />
        <p className="footer__text">
          © Copyright{' '}
          <a
            href={
              networks &&
              networks.find((item) => {
                return item.name === 'GitHub';
              }).url
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            Parsa Hejabi
          </a>{' '}
          | {new Date().getFullYear() === 2020 ? '2020' : `2020-${new Date().getFullYear()}`}
        </p>

        {isEnabled && <GithubButtons />}
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  hero: PropTypes.string.isRequired,
};

export default Footer;
