import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Parsa Hejabi | Personal Website', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome To My Personal Website!', // e.g: Welcome to my website
  author: 'Parsa Hejabi',
};

// HERO DATA
export const heroData = {
  title: 'Hello there,',
  name: 'Parsa Hejabi',
  subtitle: [
    'I am an M.S. Student at the University of Southern California',
    'I am an M.S. Student in Computer Science (Artificial Intelligence)',
    'I am a Software Engineer',
    'I am a full-stack developer',
    'I am a Piano player',
    'I am a music composer',
    'I am a half-marathon runner',
  ],
  cta: 'Scroll Down',
  networks: [
    {
      id: nanoid(),
      networkName: 'LinkedIn',
      url: 'https://linkedin.com/in/parsa-hejabi',
    },
    {
      id: nanoid(),
      networkName: 'GitHub',
      url: 'https://github.com/parsahejabi',
    },
    {
      id: nanoid(),
      networkName: 'YouTube',
      url: 'https://www.youtube.com/channel/UC0OeigWfIB9-zrZM5mVc8gA/',
    },
    {
      id: nanoid(),
      networkName: 'Slides',
      url: 'https://slides.com/parsahejabi',
    },
  ],
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.png',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
  resume: '', // if no resume, the button will not show up
  skills: [
    {
      id: nanoid(),
      skillName: 'Java',
      level: 85,
    },
    {
      id: nanoid(),
      skillName: 'JavaScript (Express, React, Node.js)',
      level: 85,
    },
    {
      id: nanoid(),
      skillName: 'Python (NumPy, Pandas, TensorFlow)',
      level: 75,
    },
    {
      id: nanoid(),
      skillName: 'C/C++',
      level: 70,
    },
    {
      id: nanoid(),
      skillName: 'PHP (Symfony)',
      level: 65,
    },
  ],
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'KaggleNewsClassification.png',
    title: 'Persian news classification using BERT language model',
    info: 'Ranked 1st on the public leadeboard of the kaggle contest using BERT language model.',
    info2: '',
    url: 'https://colab.research.google.com/github/ParsaHejabi/ComputationalIntelligence-ComputerAssignments/blob/main/FinalProject/CI_FinalProject_BERT.ipynb',
    repo: 'https://github.com/ParsaHejabi/ComputationalIntelligence-ComputerAssignments', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'Soha-DCS.png',
    title: 'Soha Data Collection System',
    info: "I implemented a crowdsourcing website to collect users' possible inputs to the SOHA system. I developed this website using the MERNG stack (MongoDB, Express.js, React, Node.js, GraphQL). Along with each input text, users could also enter the type, possible reference, and whether that input was an insult, a sarcastic input, or a humorous text.",
    info2: '',
    url: 'https://soha-dcs.netlify.app/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'hands-ai.png',
    title: 'A deep neural network model to recognize numbers from 0 to 5 in sign language',
    info: 'I implemented a three-layer neural network with TensorFlow, which uses 1080 64x64 pixel pictures of signs representing numbers from 0 to 5 (180 images per number) as a training set and 120 64x64 pixel photos of signs representing numbers from 0 to 5 (20 pictures per number) as a test set.',
    info2: 'Train accuracy: 99%. Test accuracy: 71.7%.',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'CriAssist.png',
    title: 'CriAssist - MERN stack web application for crisis management',
    info: 'The idea was to write an app that can be used in case of emergencies. It supposes to help the team involved in the disaster situation such as an earthquake to fill out reports about the situation and give a realistic view to the operation centers.',
    info2:
      'This project was developed with MERN (MongoDB, Express, ReactJS, NodeJS) stack, and also a React Native application was developed for this application. The back-end uses GraphQL data query and manipulation language for APIs, cutting-edge technology for back-ends.',
    url: 'https://criassist.herokuapp.com/',
    repo: 'https://github.com/ParsaHejabi/InternetEngineering-CriAssist', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'PongGame.png',
    title: 'Single Player Pong game implemented with 8086 assembly',
    info: "Implemented Pong game single-player version using 8086 Assembly language with square and Circle ball that changes color after every hit. In this game, you can move the racket using 'w' and 's' keys on the keyboard. The main challenges in this project were showing the score on top, drawing a circle using the midpoint circle algorithm, and changing the ball's movement direction correctly.",
    info2: '',
    url: '',
    repo: 'https://github.com/ParsaHejabi/MicroProcessor-Pong', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'QuizApp.png',
    title: 'SBU Quiz Web Application',
    info: ' I Implemented a Quiz Web Application to replace the paper-based quizzes of the Advanced Programming Course with Computer Delivered test. This project is related to when I was Chief Teaching Assistant of Advanced Programming Course instructed by Dr. Mojtaba Vahidi Asl.',
    info2: '',
    url: '',
    repo: 'https://github.com/ParsaHejabi/SBU-Quiz_App', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Have a project to discuss?',
  btn: '',
  email: 'parsa.hejabi@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'LinkedIn',
      url: 'https://linkedin.com/in/parsa-hejabi',
    },
    {
      id: nanoid(),
      name: 'GitHub',
      url: 'https://github.com/parsahejabi',
    },
    {
      id: nanoid(),
      name: 'YouTube',
      url: 'https://www.youtube.com/channel/UC0OeigWfIB9-zrZM5mVc8gA/',
    },
    {
      id: nanoid(),
      name: 'Slides',
      url: 'https://slides.com/parsahejabi',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
